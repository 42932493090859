import { createStore } from 'vuex'

const store = createStore({
    state() {
        // return {
        //     stUserBasic: []
        // };
    },
    // 计算属性
    getters: {
        // etUserBasic: (state) => state.stUserBasic
    },
    // 便于vue插件管理 里面定义的是函数
    mutations: {
        // utSetUserBasic(state, data) {
        //     state.stUserBasic = data;
        // }
    },
    // 后台API
    actions: {
        // 从后台获取数据
        // async ctLoadUser({ commit }) {
        //     const res = await loadUser({ wx_openid: window.localStorage.getItem('wx_openid') }); // 请求后台数据
        //     // console.log(res);
        //     commit('utSetUserBasic', res); // 更新 state
        // }

        // 保存数据到后台
    },
    modules: {

    }
})
export default store;