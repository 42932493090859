const gameRoute = [
    {
        path: '/game/packet.htm',
        name: 'gamePacket',
        meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/game/packet.vue')
    },
    {
        path: '/game/cashout.htm',
        name: 'gameCashout',
        meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/game/cashout.vue')
    }
]

export default gameRoute