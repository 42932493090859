import { request } from "./request.js";

export function wxLogin(data = {}) {

    return request({
        url: "wxLogin",
        method: "post",
        data
    })
}

export function loadWxMpAc(data={}){
    return request( {
        url : "loadWxMpAc",
        method : "get",
        data
    })
}


export function loadWxJsSdk(data={}){
    return request( {
        unloading: true,
        url : "loadWxJsSdk",
        method : "get",
        data
    })
}

export function wxTimelineShare(data={}){
    return request( {
        url : "wxTimelineShare",
        method : "post",
        data
    })
}

export function loadTravelLastTime(data={}){
    return request( {
        url : "readTravelLastTime",
        method : "get",
        data
    })
}

export function loadTravel(data={}){
    return request( {
        url : "readTravel",
        method : "get",
        data
    })
}

export function loadStaffUseCar(data={}){
    return request( {
        url : "readStaffUseCar",
        method : "get",
        data
    })
}

export function uploadStaffUseCar(data={}){
    return request( {
        url : "updateStaffUseCar",
        method : "post",
        data
    })
}

export function deleteStaffUseCar(data={}){
    return request( {
        url : "deleteStaffUseCar",
        method : "post",
        data
    })
}

// 确认开放路线时用
export function uploadTravel(data={}){
    return request( {
        url : "updateVpTravel",
        method : "post",
        data
    })
}

