import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

import '@/components/wxui/css/wxui.css';
// import '@/assets/iconFont/iconfont.css';
import '@/components/wxui/css/iconfontOnline.css';

// 根据屏幕宽度设置跟元素字体大小
import { wd } from '@/components/wxui/window/index.js';
wd.setRootFontSize();

createApp(App).use(store).use(router).mount('#app')

